@import url(https://use.typekit.net/mnu7cpv.css);
nav.main-nav {
    display: flex;
    align-items: center;
    height: 67px;
    margin-bottom: 25px;
}


nav {
    margin-bottom: 50px;
}

nav a {
    padding: 10px 25px;
    line-height: 50px;
    color: #64687c;
    font-weight: bold;
    font-size: 14px;
}

nav a:hover {
    text-decoration: none;
}

nav a.active {
    border-bottom: 2px solid #f9305b;
    color: #ffffff;
}
.translator_container__1C5p5{width:100%;margin-bottom:15px}.translator_contentEntry__3j73G{display:flex;align-items:flex-start;justify-content:center;grid-gap:1rem;gap:1rem;margin-bottom:15px}.translator_contentEntry__3j73G h4{padding:0 20px;width:200px}.translator_contentEntry__3j73G textarea{width:50%;flex-grow:1;resize:none;min-height:80px}.translator_contentEntry__3j73G .translator_textAreaLocale__3j0gV{width:25%}.translator_contentEntry__3j73G .translator_textAreaContent__2D0zW{width:75%}.translator_contentEntry__3j73G .translator_Select__3NpMy{flex-shrink:0;width:200px;margin-right:20px}.translator_translatorWrap__1AthY{display:flex;flex-direction:column;grid-gap:2rem;gap:2rem}.translator_translatorWrap__1AthY button{width:160px}@media(max-width: 1024px){.translator_container__1C5p5{width:100%;margin-bottom:15px;padding-top:15px}.translator_contentEntry__3j73G{display:flex;align-items:center;justify-content:center;grid-gap:1rem;grid-gap:1rem;gap:1rem;flex-direction:column;align-content:center;margin-bottom:15px}.translator_contentEntry__3j73G h4{padding:0 20px;width:200px}.translator_contentEntry__3j73G textarea{min-height:120px}.translator_contentEntry__3j73G .translator_textAreaLocale__3j0gV,.translator_contentEntry__3j73G .translator_textAreaContent__2D0zW{width:100%}.translator_contentEntry__3j73G .translator_Select__3NpMy{flex-shrink:0;width:200px;margin-right:20px}.translator_translatorWrap__1AthY{display:flex;flex-direction:column;grid-gap:2rem;gap:2rem}.translator_translatorWrap__1AthY button{width:160px}}
.create-announcement_inputGroup__1S3oc{display:flex;flex-direction:column;align-items:center}.create-announcement_inputField__3Pvp0{padding:10px}.create-announcement_block__yTWO7{background-color:#262626;margin:10px 0;width:100%;border-radius:4px}.create-announcement_targetContainer__1LPzK{width:100%;margin:15px}.create-announcement_buttonContainer__2WQ6k{width:100%;text-align:right;margin-top:15px}.create-announcement_announcementsSelect__PTuFn{margin-top:10px;width:25%}@media(max-width: 1024px){.create-announcement_inputGroup__1S3oc{display:flex;flex-direction:column;align-items:center}.create-announcement_inputField__3Pvp0{padding:10px}.create-announcement_block__yTWO7{background-color:#262626;margin:10px 0;width:100%;border-radius:4px}.create-announcement_announcementsSelect__PTuFn{margin-top:10px;width:90%}}
.pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
}



.pagination li {
    padding: 0 5px;
}

.pagination li:first-child {
    padding-left: 0;
    flex: 1 1;
    text-align: left;
}

.pagination li:last-child {
    padding-right: 0;
    flex: 1 1;
    text-align: right;
}

.pagination li {
    cursor: pointer;
}

.pagination li.selected {
    text-decoration: underline;
    font-weight: bold;
}

.cursor-pagination {
    display: flex;
    justify-content: space-between;
}
.cursor-pagination .back, .cursor-pagination .forward {
    cursor: pointer;
}
.cursor-pagination .back.disabled, .cursor-pagination .forward.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.styles_container__2u6eA{display:flex;flex-direction:row;flex-wrap:wrap;align-content:center;justify-content:space-around;align-items:center}.styles_field__8h_ir{width:100%;margin:10px 0;text-align:left}.styles_field__8h_ir label{margin-bottom:5px}@media(min-width: 1024px){.styles_container__2u6eA{flex-wrap:nowrap}.styles_field__8h_ir{width:30%}}
.OffsetPagination_pagination__2vG8Q {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px
}
.OffsetPagination_state__1V7K2 {
    margin-right: 4px;
    color: #77889A
}
.OffsetPagination_page__zN2G2 {
    -webkit-animation: 200ms ease;
            animation: 200ms ease;
}
.OffsetPagination_totalPages__3Olhc {

}
.OffsetPagination_button__33E9g {
    width: 32px;
    height: 32px;
    background: #252F38;
    border-radius: 4px;
    border: 1px solid #323B45;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-left: 12px;
    -webkit-animation: 200ms ease;
            animation: 200ms ease;
}
.tab-admin-analytics_container__2oaZL{display:flex;flex-direction:row;flex-wrap:wrap;align-content:center;justify-content:space-around;align-items:center}.tab-admin-analytics_box__3_M-m{display:flex;flex-direction:row;flex-wrap:wrap;align-content:center;justify-content:space-around;align-items:center}.tab-admin-analytics_pagination__10XG3{display:flex;flex-direction:row;flex-wrap:nowrap;align-content:center;justify-content:flex-end;align-items:center}.tab-admin-analytics_button__376YB{padding:10px 15px}
.waitlistdetail h1 {
  color: #fff;
  text-transform: capitalize;
}

.waitlistdetail__header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.waitlistdetail__header form {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.ticketIA__container-textarea {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
.ticketIA {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
}
.ticketIA__container {
  max-width: 992px;
  width: 100%;
}
.ticketIA__textarea {
  height: 150px;
}
.ticketIA__textarea--preview {
  height: 250px;
}
.ticketIA__select {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 10px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}
.ticketIA__form {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px 0;
}

.input-row {
    padding: 15px;
    background: #131521;
    display: flex;
}

.input-row>input {
    flex: 1 1;
    margin: 0 10px;
}

.game-page .tags {
    display: flex;

    flex-wrap: wrap;
}

.game-page .tag {
    background: #131521;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    display: flex;
    width: 200px;
    justify-content: space-between;
}

.game-page .tag .plus,
.game-page .tag .minus {
    padding: 0 10px;
    cursor: pointer;
    background: black;
    margin-left: 10px;
    border-radius: 6px;
}

.game-page .plus {
    color: springgreen;
}

.game-page .minus {
    color: orangered;
}

.blocked-countries {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.blocked-countries input {
    min-width: 500px;
}
.container {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem;
}

.button-container{ 
 display: flex;
 justify-content: flex-end;

}

.container label {
  padding-right: 1rem;
}

.file-container {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
}

.file-input-container{
  width: 80%;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px
}
.label-challenge{font-size:16px}.column{min-width:200px}.form-challenge{display:flex;flex-direction:column;grid-gap:20px;gap:20px}.currency-rates-container>table{margin-top:15px}.create-challenge-button{margin-top:20px;margin-left:30px;width:200px;height:50px;font-size:16px;margin-bottom:50px}.rewardSelect{width:500px}.time-challenges{margin-left:20px;font-size:18px;margin-top:10px}.column-rewardSelect{margin-top:20px;width:200px}.margin-left-form-challenge{padding:0 10px}.row-challenge{display:grid;grid-template-columns:repeat(auto-fit, minmax(auto, 1fr));grid-gap:20px;gap:20px}@media(max-width: 750px){.rewardSelect{width:300px}}.featured-input{width:15px;height:15px;margin-left:20px;margin-top:17px}.column.featured-column{margin-top:20px}.recurring-check-label{display:flex}.totalprize-box{display:flex;flex-direction:column}
.tabRaces_tabbedNav__1Oglt{display:flex;justify-content:flex-start;align-items:center;margin-bottom:24px}.tabRaces_tabbedNav__1Oglt>div{padding:5px 20px;cursor:pointer}.tabRaces_tabbedNav__1Oglt>div.tabRaces_selected__1NKPD{border-bottom:2px solid #f9305b}.tabRaces_tabbedNav__1Oglt>div:not(.tabRaces_selected__1NKPD){color:#6f7393}.tabRaces_inputs__3Ln2l{display:flex;align-items:center;justify-content:space-evenly;margin-bottom:14px}@media(max-width: 1024px){.tabRaces_tabbedNav__1Oglt{justify-content:space-evenly}.tabRaces_inputs__3Ln2l{display:flex;align-items:flex-start;justify-content:space-between;flex-wrap:wrap;align-content:flex-start;flex-direction:column}}.tabRaces_selected__1NKPD{font-weight:bold}
.race-create__row{border:1px solid rgba(221,51,221,.2);border-radius:4px;display:flex;flex-direction:column;grid-gap:15px;gap:15px;padding:15px;margin:15px 0;text-align:left}.race-create__row_no_box{border-radius:4px;display:flex;flex-direction:column;grid-gap:15px;gap:15px;padding:15px;margin:15px 0;text-align:left}.create-race{padding:15px;grid-gap:15px;gap:15px}.race-create__check{display:flex;align-items:center;grid-gap:15px;gap:15px}.race-create__check input{width:30px;height:30px}.race-create__prize{display:flex;flex-direction:column;grid-gap:15px;gap:15px}.race-create-button{margin-left:15px}.race-row{display:grid;grid-template-columns:repeat(auto-fit, minmax(auto, 1fr));grid-gap:20px;gap:20px}.label-race{font-size:16px}.games-container{display:flex;flex-direction:column;grid-gap:15px;gap:15px;width:50%}.games-container button+button{margin-left:10px}.games-container button{max-width:160px}.games-container h2{margin:5px 0}.selected-games-container{background-color:#3b3f50;padding:10px;border:1px solid rgba(221,51,221,.2);border-radius:4px;min-width:50%}
.label-challenge{font-size:16px}.column{margin-left:20px}.create-challenge-button{margin-top:20px;margin-left:30px;width:200px;height:50px;font-size:16px;margin-bottom:50px}.rewardSelect{width:500px}.pagination{display:flex;justify-content:flex-end;align-items:center;justify-content:center;margin:24px 0}.btn-pagination{background:#f9305b;color:#fff;border:none;display:flex;align-items:center;justify-content:center;padding:10px 20px;border-radius:4px;cursor:pointer;font-size:14px;font-weight:600;margin-left:10px;height:25px}.pagination_page{margin-left:10px;margin-right:10px;font-size:16px;font-weight:600;color:#6f7393;cursor:pointer;font-size:12px}.pagination_total{margin-left:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:10px;margin-right:10px;font-size:16px;font-weight:600;color:#6f7393;cursor:pointer;font-size:12px}.column-rewardSelect{margin-top:20px;width:200px}.row-challenge{display:flex;flex-direction:row}.text-center{text-align:center}.pr-30{padding-right:30px}.mr-20{margin-right:20px}.between{justify-content:space-between}.amount-deviation-container{background-color:#f9305b;border-radius:4px;font-size:14px;font-weight:bold;font-style:normal;font-stretch:normal;line-height:normal;letter-spacing:-0.3px;color:#ddd;padding:5px 15px 5px 15px;border:none;display:flex;flex-direction:column;align-items:center}.amount-deviation{font-size:18px}@media(max-width: 750px){.row-challenge{display:flex;flex-direction:column}.rewardSelect{width:300px}}.box-select{width:300px;margin-left:30px}.box-selects{display:flex;justify-content:flex-end}.text-box-select{font-size:16px}
.selectsort{max-width:360px;margin-left:auto;margin-bottom:20px}
.selectsort{max-width:360px;margin-left:auto;margin-bottom:20px}


#promo .create {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;
}

#promo .create .Select {
    width: 500px;
    margin-bottom: 10px;
}
.wheels .items-select {
  padding: 15px;
  background: #16171b;
  display: flex;
  align-items: center;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 500px;
}

.wheels .wheel-item img {
  max-height: 60px;
  max-width: 60px;
}

.wheels .wheel-item .value {
  color: green;
}

.wheels .wheel-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 15px;
  flex-basis: 25%;
  max-width: 25%;
  cursor: pointer;
}

.filters-query {
  display: flex;
  margin-bottom: 50px;
}
.filter-query {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  padding: 0 10px 25px;
}

.rewards-search-row {
  background: #131521;
  padding: 15px;
  border-radius: 15px;
  height: 78px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 80%;
  margin-left: 10%;
}

.rewards-search-row input {
    width: 100%;
}

.rewards-wrapper-container {
  width: 100%;
  padding: 0 30px;
}
.mystery-box-table-header{
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.globalXp-group-inputs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 1rem 0;
  grid-gap: 10px;
  gap: 10px;
}
.globalXp-group-inputs > label {
  text-align: left;
}
.globalXp-section {
  margin: 1rem 0;
  max-width: 550px;
}

.globalXp-group-btn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-top: 50px;
}
.globalXp-title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.globalXp-container {
  padding: 20px;
}

.group-checkbox {
  display: inline-block;
  width: 100%;
  margin-bottom: 10px;
  margin: 20px 0;
}

.games-category-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 200px));
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
}

.group-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
}
.group-input > input {
  height: 48px;
  width: 100%;
  max-width: 350px;
}
.group-input > button {
  width: 100%;
  max-width: 350px;
}

.category-select {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  flex-wrap: wrap;
}
.view-category__new-category {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 50px;
}
.category-page__game {
  margin: 20px 0;
}
.category-select .category-box {
  background: #131521;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 4px;
}

.category-select .category-box:hover,
.category-select .category-box.selected {
  background: #232639;
}
.category-page {
  padding: 20px;
}
.category-page .games > div {
  margin-bottom: 12px;
}

.game-box {
  background: #131521;

  padding: 12px;
  overflow-wrap: break-word;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;

  position: relative;
}

.game-box .remove {
  position: absolute;
  top: 5px;
  right: 5px;
}

.game-box .remove:hover {
  color: red;
}

.game-box:hover {
  background: #232639;
}

.game-box p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  margin: 0;
}

.game-box img {
  width: 100%;
  overflow: hidden;
  float: left;
}

.game-box.inCategory {
  border: 2px solid #006d3f;
}

.game-box input {
  width: 100%;
}

.game-box .buttons {
  display: flex;
  margin: 10px 0;
}

.game-box .buttons button {
  padding: 2px;
  width: 100%;
  font-size: 14px;
}


.bet-page .bet {
    background: #191b22;
}

.bet-page .bet .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.bet-page .bet .top .left {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.bet-page .bet .top .right {
    display: flex;
    align-items: flex-end;
}
.bet-page .bet .stats {
    font-size: 12px;
}
.bet-page .bet .maximize {
    color: greenyellow;
    margin-left: 25px;
    cursor: pointer;
}
.bet-page .bet-journal-entries .journal-entries {
    border-bottom: none
}

.bet-page .event-stream .payload {
    font-size: 14px;
}

.bet-page .bet-data {
    padding: 15px;
}
.container-input-affiliate-link {
  padding-bottom: 2rem;
  padding: 1rem;
  border-radius: 5px;
}

.wrap-switch-affiliate {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.wrap-switch-affiliate > p {
  margin: 0;
  font-size: 16px;
}

.affiliate-link-flex input {
  margin: 0 !important;
}

@media (min-width: 768px) {
  .affiliate-link-flex {
    grid-gap: 2rem;
    gap: 2rem;
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
.affiliate-link-container {
  padding: 20px;
  height: 600px;
}

.affiliate-link-container > button {
  margin: auto;
  width: 160px;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.affiliate-translate {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(350px, 1fr)); */
  grid-gap: 2rem;
  gap: 2rem;
}

.affialiate-link-actions {
  margin: 0;
  padding: 0;
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}
.affialiate-link-actions li {
  list-style: none;
}
.update-affiliate-groups-select {
  margin-bottom: 2rem;
}

.translate-update {
}
.translate-update label {
  text-transform: capitalize;
}
.translate-update__lang p {
  margin: 0;
}
.translate-update__lang {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.update-affiliate-groups {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;

  margin-bottom: 2rem;
}
.update-affiliate-group-input {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.input-calendar {
  background: #3b3f50;
  color: white;
  border: 1px solid #3b3f50;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
}
.input-calendar > p {
  margin: 0;
  text-align: center;
  font-size: 12px;
}
.input-calendar span {
  color: #8c9099;
}

.calendar {
  background: var(--secondary-color);
  border: 0;
  border-radius: 4px solid;
  box-shadow: 0px 5px 20px rgba(6, 10, 14, 0.4);
}
.calendar .react-datepicker__current-month {
  color: white;
}
.calendar .react-datepicker__day-name {
  color: #8c9099 !important ;
}
.calendar .start-range {
  background-color: #f12c4c !important ;
  border-radius: 50% !important;
  position: relative;
  z-index: 4 !important;
}

.calendar .end-range {
  background-color: #f12c4c;
  border-radius: 50%;
}

.calendar .react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #4e2e3c;
  color: #fff;
}
.react-datepicker__day--in-selecting-range {
  background: #4e2e3c !important;
}
/* .calendar .react-datepicker__day--selected,
.eact-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0;
} */

.calendar .react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  /* margin: 0.166rem 0; */
  color: #ffff;
}
.calendar .react-datepicker__header {
  background-color: var(--secondary-color);
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #f12c4c;
  color: white;
}
.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--today {
  background: #464e56;
  border-radius: 50%;
}

.calendar-container {
  display: flex;
}

.calendar-presets {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  width: 158px;
  padding-top: 16px;
  background: #0f1923;
}
.calendar-container li:first-child {
  color: #8c9099;
  font-size: 11px;
}
.calendar-container li {
  list-style: none;
  color: #ffffff;
  margin: 4px 12px;
  padding-left: 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.calendar-container .li--select {
  background: rgba(37, 47, 56, 0.5);
  border: 1px solid #323b45;
}

.react-datepicker__month-container {
  padding-top: 10px !important;
  background-color: #0f1923;
}

@media (max-width: 1024px) {
  .calendar-presets {
    left: -60px;
    position: relative;
  }
}

.label-challenge{font-size:16px}.column{min-width:200px}.form-challenge{display:flex;flex-direction:column;grid-gap:20px;gap:20px}.create-challenge-button{margin-top:20px;margin-left:30px;width:200px;height:50px;font-size:16px;margin-bottom:50px}.rewardSelect{width:500px}.time-challenges{margin-left:20px;font-size:18px;margin-top:10px}.column-rewardSelect{margin-top:20px;width:200px}.margin-left-form-challenge{padding:0 10px}.row-challenge{display:grid;grid-template-columns:repeat(auto-fit, minmax(auto, 1fr));grid-gap:20px;gap:20px}@media(max-width: 750px){.rewardSelect{width:300px}}.featured-input{width:15px;height:15px}.column.featured-column{margin-top:20px}
.tabChallenges_tabbedNav__1sEOz{display:flex;justify-content:flex-start;align-items:center;margin-bottom:24px}.tabChallenges_tabbedNav__1sEOz>div{padding:5px 20px;cursor:pointer}.tabChallenges_tabbedNav__1sEOz>div.tabChallenges_selected__2m7bW{border-bottom:2px solid #f9305b}.tabChallenges_tabbedNav__1sEOz>div:not(.tabChallenges_selected__2m7bW){color:#6f7393}.tabChallenges_inputs__2ChmW{display:flex;align-items:center;justify-content:space-evenly;margin-bottom:14px}@media(max-width: 1024px){.tabChallenges_tabbedNav__1sEOz{justify-content:space-evenly}.tabChallenges_inputs__2ChmW{display:flex;align-items:flex-start;justify-content:space-between;flex-wrap:wrap;align-content:flex-start;flex-direction:column}}.tabChallenges_selected__2m7bW{font-weight:bold}
.presetLoad {
    margin-bottom: 20px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
}

.presetLoad__info {
    max-width: 550px;
    margin-bottom: 20px;
}

.presetLoad__info>p {
    margin: 0;
    color: #dadada;
}

.presetLoad__select {
    height: 48px;
    border-radius: 4px;
}

.presetLoad__btn {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

@media (min-width:768px) {
    .presetLoad {
        flex-direction: row;
    }
}
.label-challenge{font-size:16px}.column{margin-left:20px}.create-challenge-button{margin-top:20px;margin-left:30px;width:200px;height:50px;font-size:16px;margin-bottom:50px}.rewardSelect{width:500px}.pagination{display:flex;justify-content:flex-end;align-items:center;justify-content:center;margin:24px 0}.btn-pagination{background:#f9305b;color:#fff;border:none;display:flex;align-items:center;justify-content:center;padding:10px 20px;border-radius:4px;cursor:pointer;font-size:14px;font-weight:600;margin-left:10px;height:25px}.pagination_page{margin-left:10px;margin-right:10px;font-size:16px;font-weight:600;color:#6f7393;cursor:pointer;font-size:12px}.pagination_total{margin-left:auto;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;margin-top:10px;margin-right:10px;font-size:16px;font-weight:600;color:#6f7393;cursor:pointer;font-size:12px}.column-rewardSelect{margin-top:20px;width:200px}.row-challenge{display:flex;flex-direction:row}@media(max-width: 750px){.row-challenge{display:flex;flex-direction:column}.rewardSelect{width:300px}}.box-select{width:300px;margin-left:30px}.box-selects{display:flex;justify-content:flex-end}.text-box-select{font-size:16px}
.selectsort{max-width:360px;margin-left:auto;margin-bottom:20px}
.selectsort{max-width:360px;margin-left:auto;margin-bottom:20px}
.tabRaceDetail_tabbedNav__jZjfC{display:flex;justify-content:flex-start;align-items:center;margin-bottom:24px}.tabRaceDetail_tabbedNav__jZjfC>div{padding:5px 20px;cursor:pointer}.tabRaceDetail_tabbedNav__jZjfC>div.tabRaceDetail_selected__21Zys{border-bottom:2px solid #f9305b}.tabRaceDetail_tabbedNav__jZjfC>div:not(.tabRaceDetail_selected__21Zys){color:#6f7393}.tabRaceDetail_inputs__3WjRC{display:flex;align-items:center;justify-content:space-evenly;margin-bottom:14px}@media(max-width: 1024px){.tabRaceDetail_tabbedNav__jZjfC{justify-content:space-evenly}.tabRaceDetail_inputs__3WjRC{display:flex;align-items:flex-start;justify-content:space-between;flex-wrap:wrap;align-content:flex-start;flex-direction:column}}.tabRaceDetail_selected__21Zys{font-weight:bold}


.settings {
    display: flex;
}

.settings .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    cursor: pointer;
}

.settings .input-group  > * {
    width: auto;
    margin-bottom: 0;
    margin-right: 5px;
}

.user-search-row {
    background: #131521;
    padding: 15px;
    border-radius: 15px;
    height: 78px;
    display: flex;
}
.user-search-row input {
    flex: 1 1;
}
.user-search-row input, .user-search-row button {
    height: 100%;
}
.user-finder {
    min-height: 500px;
    position: relative;
}

.user-page .nav-output {
  flex: 1 1;
  overflow-x: auto;
  margin-top: 13px;
}
.user-page .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-page .header img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.user-page .header .wallet-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user-page .header .wallet-select .Select {
  width: 200px;
  margin-left: 12px;
}

.user-page .header .wallet-select .Select .Select-clear {
  display: none;
}

.user-page .header .user-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-page nav {
  display: flex;
  flex-wrap: wrap;
}

.user-page nav a {
  display: block;
  padding: 16px;
  font-size: 12px;
}
.user-page nav a.active {
  border: none;
  border-bottom: 2px solid #f9305b;
}

.user-page .header .stacked {
  display: flex;
  flex-direction: column;
}

.user-page .user-role {
  display: flex;
  align-items: center;
}

.user-page .user-role span {
  margin-right: 20px;
}

.user-roles-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 15px;
}

.user-page .changed {
  border: 2px solid rgba(27, 182, 127, 0.7);
}

.user-roles-filter .input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 25px;
}

.user-roles-filter .input-group > * {
  width: unset;
}

.user-page .document {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 200px;
  background: #3b3f50;
}

.user-page .documents {
  display: flex;
}

.user-page .stat {
  margin-bottom: 50px;
}

.user-page .stat h3 {
  text-transform: uppercase;
  font-size: 14px;
}

.user-page .stat h4 {
  font-weight: 400;
}

.document-modal .close {
  font-size: 20px;
  position: relative;
  right: 0;
  color: red;
  margin-right: 0px;
  cursor: pointer;
  text-align: right;
}

.document-modal.upload-modal > div {
  background: #1b2430 !important;
}

#kyc-emails .tabbed-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
}

#kyc-emails .tabbed-nav > div {
  padding: 5px 20px;
  cursor: pointer;
}

#kyc-emails .tabbed-nav > div.selected {
  border-bottom: 2px solid #f9305b;
}

#kyc-emails .kyc-created-email .inputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 14px;
}

#kyc-emails .kyc-created-email .inputs > div {
  margin-right: 14px;
  display: flex;
  align-items: center;
}

#kyc-emails .kyc-created-email .inputs > div label {
  margin-right: 10px;
}

#kyc-emails .declined-reasons {
  margin-left: 30px;
}

#kyc-emails .Select {
  width: 300px;
  margin: 14px 0;
}

#kyc-emails button {
  margin: 14px 0;
}

.user-page .accounting-section {
  border: 1px solid #2c2c2d;
  border-radius: 25px;
  padding: 25px;
}

.user-page .accounting-section .accounting-section-row {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #7b8ca9;
}

.user-page .accounting-section .accounting-section-row:not(:last-child) {
  border-bottom: 1px solid #2c2c2d;
}
.user-page .summary-row {
  line-height: 3;

  margin: 0 28px;
}
.user-page .summary-row .line-item {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}

@media (max-width: 1024px) {
  .user-page .nav-output {
    overflow-x: hidden;
  }
  #kyc-emails .Select {
    width: auto;
  }
}

.roles_form__1yOVt{padding-bottom:30px;margin-bottom:30px;border-bottom:1px solid #64687c}.roles_editRoles__2SSGu{display:flex;flex-direction:row;align-content:center;justify-content:flex-start;align-items:center}.roles_editRoles__2SSGu .Select{width:450px;margin-right:20px}.roles_editRoles__2SSGu button{padding:9px 25px}.roles_editRoles__2SSGu input{margin-right:20px;width:50%}.roles_actionButton__VxRlV{padding:9px 25px}.roles_closeAccount__1dGOG{border:1px solid #f9305b;background-color:#602c2c;padding:25px;border-radius:4px;text-align:right}@media(max-width: 1024px){.roles_editRoles__2SSGu{width:100%;display:flex;flex-direction:column;align-content:center;justify-content:space-between;align-items:center;height:100px}.roles_editRoles__2SSGu input{width:100%;margin-right:0}}
@media(max-width: 1024px){.anti-fraud_info__1vou9{display:flex;flex-direction:column;flex-wrap:nowrap;align-content:center;justify-content:space-around;align-items:center;width:100%}}
.transactions_filtersQuery__D1G0H{display:flex;margin-bottom:50px;flex-direction:row;flex-wrap:wrap;align-content:center;justify-content:center;align-items:flex-end;margin-bottom:50px}.transactions_filterQuery__3DxMy{display:flex;flex-direction:row;flex:1 1;padding:0 10px 25px;align-content:stretch;justify-content:flex-start;align-items:center;width:25%;flex-wrap:wrap}.transactions_filterQuery__3DxMy>h5{line-height:20px}.transactions_filterQuery__3DxMy>input{width:100%}.transactions_filterQuery__3DxMy .Select{width:100%}.transactions_buttonExport__eDha_{min-height:24px;padding:5px;font-weight:600;font-size:14px}.transactions_buttons__3uc0R{display:flex;justify-content:flex-end;align-items:center;flex-wrap:nowrap;align-content:center}.transactions_buttons__3uc0R>button{margin:10px 5px}@media(max-width: 1024px){.transactions_filtersQuery__D1G0H{display:flex;margin-bottom:50px;flex-direction:column;align-content:stretch;justify-content:center;align-items:stretch;margin-bottom:50px}.transactions_filterQuery__3DxMy{display:flex;flex-direction:column;flex:1 1;padding:0 10px 25px;flex-wrap:nowrap;align-content:center;justify-content:center;align-items:stretch;background-color:none;width:100%}.transactions_buttons__3uc0R{display:flex;flex-direction:row;justify-content:space-evenly;align-items:center;flex-wrap:wrap;align-content:center}}
.bonus-rounds_form__2hZqp{padding-bottom:30px;margin-bottom:30px;border-bottom:1px solid #64687c}.bonus-rounds_editRoles__2pYrF{display:flex;flex-direction:row;align-content:center;justify-content:flex-start;align-items:center}.bonus-rounds_editRoles__2pYrF .Select{width:450px;margin-right:20px}.bonus-rounds_editRoles__2pYrF button{padding:9px 25px}.bonus-rounds_editRoles__2pYrF input{margin-right:20px;width:10%}.bonus-rounds_actionButton__35IBC{padding:9px 25px}.bonus-rounds_closeAccount__2jjur{border:1px solid #f9305b;background-color:#602c2c;padding:25px;border-radius:4px;text-align:right}@media(max-width: 1024px){.bonus-rounds_editRoles__2pYrF{width:100%;display:flex;flex-direction:column;align-content:center;justify-content:space-between;align-items:center;height:140px}.bonus-rounds_editRoles__2pYrF input{width:100%;margin-right:0}}
.export-modal-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.financials_main__1srQU .Select{width:25%}.financials_column__2xOaH{flex-basis:25%;max-width:25%}.financials_buttons__YDYSy{margin-bottom:25px}.financials_buttons__YDYSy>button{margin-right:25px}.financials_section__-u-IY{display:flex}@media(max-width: 1024px){.financials_summary__1haJj{max-width:100%;width:100%;flex-basis:100%}.financials_column__2xOaH{flex-basis:100%;max-width:100%}.financials_buttons__YDYSy{display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-around;align-items:center}.financials_buttons__YDYSy>button{margin-right:0}.financials_main__1srQU .Select{width:100%}}
.styles_panel__2Mov6{padding-bottom:10px;border-bottom:1px solid #64687c;display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center;align-content:center;flex-direction:row}.styles_select_gender__3uBQy{background:#3b3f50;color:#fff;padding:10px 15px;border-radius:5px;border:none;width:100%}.styles_titlePanel__fHDgG{text-align:left;width:100%}.styles_form__jxDhJ{display:flex;flex-wrap:wrap;flex-direction:row;align-content:center;justify-content:flex-start;align-items:center}.styles_formSelect__3sPJ5{}.styles_field__16KSJ{height:100px;width:250px;padding:5px;text-align:left;margin-right:24px}.styles_message__3LAp7{font-size:12px;font-style:italic;color:red;text-align:right}.styles_input__2vkae input{width:100%}.styles_buttons__1TqBP{display:flex;flex-direction:row;flex-wrap:wrap;justify-content:space-evenly;align-content:center;align-items:center}.styles_buttons__1TqBP button{margin:10px}.styles_stat__1Z2rN{border-radius:4px;border:1px solid #64687c;padding:15px;margin:5px;min-width:160px;display:flex;flex-direction:column;align-content:flex-end;justify-content:center;align-items:flex-start}.styles_singleInput__1QchP{width:30%}@media(max-width: 1024px){.styles_buttons__1TqBP{flex-direction:column;width:100%}.styles_panel__2Mov6{justify-content:space-between}.styles_field__16KSJ{width:95%}.styles_formSelect__3sPJ5{width:100%}.styles_form__jxDhJ{justify-content:center}.styles_field__16KSJ{margin-right:0}.styles_singleInput__1QchP{width:100%}}
.DocumentNotices_tabbedNav__3g9TP{display:flex;justify-content:flex-start;align-items:center;margin-bottom:24px}.DocumentNotices_tabbedNav__3g9TP>div{padding:5px 20px;cursor:pointer}.DocumentNotices_tabbedNav__3g9TP>div.DocumentNotices_selected__2Mh-_{border-bottom:2px solid #f9305b}.DocumentNotices_tabbedNav__3g9TP>div:not(.DocumentNotices_selected__2Mh-_){color:#6f7393}.DocumentNotices_inputs__2w00L{display:flex;align-items:center;justify-content:space-evenly;margin-bottom:14px}@media(max-width: 1024px){.DocumentNotices_tabbedNav__3g9TP{justify-content:space-evenly}.DocumentNotices_inputs__2w00L{display:flex;align-items:flex-start;justify-content:space-between;flex-wrap:wrap;align-content:flex-start;flex-direction:column}}
.user_stuck{background:rgba(255,255,255,.1);border:1px solid #f9305b;border-radius:4px;margin-bottom:20px;overflow:hidden}.user_stuck_title{display:inline}.user_stuck_container{display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:20px;padding:0 25px 25px;height:100%}@media screen and (max-width: 800px){.user_stuck_container{grid-template-columns:1fr}}.user_stuck_action_button{margin-top:20px}.user_stuck_header{display:flex;justify-content:space-between;align-items:center;padding:25px 25px 20px;cursor:pointer}.user_stuck_collapse_icon{-webkit-transform:rotate(90deg);transform:rotate(90deg)}.user_stuck_open .user_stuck_collapse_icon{-webkit-transform:rotate(270deg);transform:rotate(270deg)}
.bets-section{position:relative;min-height:500px}
.check-cpf-container {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
}

.key-container div {
  width: 100%;
}

.check-cpf-container .cpf-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
}

.cpf-input button {
  width: 200px;
}

table.paleBlueRows {
  font-family: "Times New Roman", Times, serif;
  border: 1px solid #ffffff;
  text-align: center;
  border-collapse: collapse;
}

table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 2px;
}

table.paleBlueRows tbody td {
  font-size: 16px !important;
  background-color: transparent;
}

table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}

table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}

table.paleBlueRows thead th {
  font-size: 16px !important;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #ffffff;
}

table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot {
  font-size: 16px !important;
  font-weight: bold;
  color: #333333;
  background: #d0e4f5;
  border-top: 3px solid #444444;
}

table.paleBlueRows tfoot td {
  font-size: 16px !important;
}

.withdrawal-page section {
    background: #131521;
    border-radius: 15px;
    padding: 15px;
    margin-bottom: 50px;
}

.tier-button {
    color: #ffffff;
    border-bottom: 2px solid transparent;

    padding: 25px;
    font-weight: bold;
    font-size: 14px;
    background: transparent;
    border-radius: 0;
}

.tier-button--active{
    border-bottom: 2px solid #f9305b;
}

.tabs-tier {
    display: flex;
    grid-gap:20px;
    gap:20px;
}

.withdrawal-page section .line-item {
    border-bottom: 2px solid #2c2c2d;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.withdrawal-page section .line-item.kyc-level .Select {
    width: 100%;
    margin: 0 10px;
}

.withdrawal-page section .line-item.kyc-level .label {
    white-space: nowrap;
}

.withdrawal-page section .line-item > .refund {
    width: 100%;
}

.withdrawal-page section .line-item button.small {
    font-size: 10px;
}

.withdrawal-page section .line-item.red {
    color: #f12c4c;
}

.withdrawal-page .row>* {
    padding: 0 45px;
}

.withdrawal-page .mutuals .row > div {
    max-height: 500px;
    overflow-y: auto;
}

.withdrawal-page section .line-item .label {
    font-size: 12px;
    text-transform: uppercase;
}
.withdrawal-page section .line-item .value {
    font-size: 12px;
}

.withdrawal-page h2 {
    font-size: 20px;
    font-weight: 500;
}

.withdrawal-page {
    position: relative;
}


.pix-controller {

    display: flex;
    padding: 16px 0;
}

.pix-controller button {
    height: 40px;
    padding: 0 15px;
    margin-right: 16px;
}

.withdrawal-page .user-page .accounts {
    overflow-x: auto;
}

.automated-withdrawals span.separator {
    margin: 0 5px;
    border-right: 2px solid rgba(255, 255, 255, 0.3)
}

input.automated-input {
    width: 100%;
}
.reporting-section {
    border: 1px solid #2c2c2d;
}
.reporting-section .header {
    border-bottom: 1px solid #2c2c2d;
    padding: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.reporting-section .body {
    padding: 25px;
    position: relative;
}
.reporting-section, .reporting-section .body {
    min-height: 500px;
}

.reporting-section .body h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 25px;
}
@-webkit-keyframes fade-in {
    from {opacity: 0}
    to {opacity: 1}
}
@keyframes fade-in {
    from {opacity: 0}
    to {opacity: 1}
}


.loader {
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: linear-gradient(to right, #f9305b 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.loader:before {
    width: 50%;
    height: 50%;
    background: #f9305b;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.loader:after {
    background: #1d1e25;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.affiliates-search-row {
  background: #131521;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.affiliates-search-row input {
  flex: 1 1;
}

.affiliates-search-row input,
.affiliates-search-row button {
  height: 100%;
}

.affiliates-finder {
  min-height: 500px;
  position: relative;
}

@media (min-width: 550px) {
  .affiliates-search-row {
    display: flex;
    flex-direction: row;
  }
}

.affiliate-page.postback {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: 5px;
}

.affiliate-page__group-input {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}
@media (min-width: 550px) {
  .affiliate-page__group-input {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
  }
}
.affiliate-page__container {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  margin: auto;
  margin-bottom: 50px;
  max-width: 750px;
}
.affiliate-page__container > button {
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
}
.affiliate-page__group-input > label {
  text-align: left;
}
.affiliate-page__group-input > select {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  font-size: 14px;
  color: #3b3f50;
  background: #fff;
  outline: none;
}
.postback-button {
  margin-top: 10px;
  width: 50%;
}

.affiliate-page .nav-output {
  flex: 1 1;
  overflow-x: auto;
  margin-top: 13px;
}
.affiliate-page .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.affiliate-page .header img {
  width: 50px;
  border-radius: 50%;
  margin-right: 15px;
}

.affiliate-page .header .wallet-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.affiliate-page .header .wallet-select .Select {
  width: 200px;
  margin-left: 12px;
}

.affiliate-page .header .wallet-select .Select .Select-clear {
  display: none;
}

.affiliate-page .header .user-header-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.affiliate-page nav {
  display: flex;
  flex-wrap: wrap;
}

.affiliate-page nav a {
  display: block;
  padding: 16px;
  font-size: 12px;
}
.affiliate-page nav a.active {
  border: none;
  border-bottom: 2px solid #f9305b;
}

.affiliate-page .header .stacked {
  display: flex;
  flex-direction: column;
}

.affiliate-page .user-role {
  display: flex;
  align-items: center;
}

.affiliate-page .user-role span {
  margin-right: 20px;
}

.user-roles-filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  overflow-x: auto;
  margin: 0 15px;
}

.affiliate-page .changed {
  border: 2px solid rgba(27, 182, 127, 0.7);
}

.user-roles-filter .input-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 25px;
}

.user-roles-filter .input-group > * {
  width: unset;
}

.affiliate-page .document {
  padding: 15px;
  cursor: pointer;
  border-radius: 4px;
  max-width: 200px;
  background: #3b3f50;
}

.affiliate-page .documents {
  display: flex;
}

.affiliate-page .balances {
  display: flex;
  grid-gap: 4rem;
  gap: 4rem;
}

.EZDrawer .EZDrawer__checkbox{display:none}.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__overlay{display:block;opacity:1}.EZDrawer .EZDrawer__checkbox:checked~.EZDrawer__container{visibility:visible;-webkit-transform:translate3d(0, 0, 0) !important;transform:translate3d(0, 0, 0) !important}.EZDrawer .EZDrawer__overlay{display:none;height:100vh;left:0;position:fixed;top:0;width:100%}.EZDrawer .EZDrawer__container{position:fixed;visibility:hidden;background:#131521;transition:all;box-shadow:0 0 10px 5px rgba(0,0,0,.1)}
.styles_parentPath__2gMJT{color:gray;color:#f9305b;font-size:12px;text-align:left}.styles_parentPath__2gMJT:after{content:" > ";color:gray}.styles_parentPath__2gMJT:last-child:after{content:""}.styles_toggleMenu__2XgGX{width:24px}.styles_topDivider__ItodY{border-top:2px solid #64687c}.styles_item__z7Z4E{display:flex;flex-direction:row;justify-content:space-between;align-content:center;align-items:center;border-bottom:1px solid #323b45}.styles_item__z7Z4E>a{padding:10px}.styles_icon__2oeoL{margin-right:10px}
.styles_ul__qKaza{list-style-type:none;padding:0}.styles_icon__37b8h{margin-left:10px}.styles_burger__1QHXN{background-color:#f9305b;padding:8px;position:fixed;border-radius:5px;bottom:20px;left:20px;z-index:4}.styles_search__30qVb{display:flex;flex-direction:row;flex-wrap:nowrap;align-content:center;justify-content:space-between;align-items:center;margin:10px;background-color:#3b3f50;border-radius:4px}.styles_search__30qVb input{width:90%}.styles_search__30qVb input:focus-visible{outline:none}.styles_search__30qVb span{display:inline-block;background-color:#fff;color:#000;border-radius:50%;font-size:18px;line-height:24px;width:24px;height:24px;text-align:center;margin:0 10px}.styles_drawer__21R9Y{overflow-y:auto}
.box-selects{justify-content:flex-start}.box-selects-status{display:flex;flex-direction:row}@media(max-width: 760px){.box-selects-status{flex-direction:column}}.status-indicator{display:inline-block;width:15px;height:15px;border-radius:50%;margin-right:5px;box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);transition:all .3s cubic-bezier(0.25, 0.8, 0.25, 1)}.status-indicator.online{background-color:green;box-shadow:0 0 10px 0 green,0 0 5px 0 lime,inset 0 0 5px green}.status-indicator.offline{background-color:red;box-shadow:0 0 10px 0 red,0 0 5px 0 red,inset 0 0 5px red}.status-indicator.disrupted{background-color:#ff0;box-shadow:0 0 10px 0 #ff0,0 0 5px 0 #ff0,inset 0 0 5px #ff0}
.selectcategory{max-width:360px;margin-left:auto;margin-bottom:20px}
.selecttype{max-width:360px;margin-left:auto;margin-bottom:20px}
.box-selects{justify-content:flex-start}.box-selects-status{display:flex;flex-direction:row}@media(max-width: 760px){.box-selects-status{flex-direction:column}}.status-indicator{display:inline-block;width:15px;height:15px;border-radius:50%;margin-right:5px;box-shadow:0 1px 3px rgba(0,0,0,.12),0 1px 2px rgba(0,0,0,.24);transition:all .3s cubic-bezier(0.25, 0.8, 0.25, 1)}.status-indicator.online{background-color:green;box-shadow:0 0 10px 0 green,0 0 5px 0 lime,inset 0 0 5px green}.status-indicator.offline{background-color:red;box-shadow:0 0 10px 0 red,0 0 5px 0 red,inset 0 0 5px red}.status-indicator.disrupted{background-color:#ff0;box-shadow:0 0 10px 0 #ff0,0 0 5px 0 #ff0,inset 0 0 5px #ff0}
.selector{max-width:360px;margin-left:auto;margin-bottom:20px}
.timezone-select-container {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

.timezone-select-input {
  display: flex;
  grid-gap: 10px;
  grid-gap: 20px;
  gap: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  margin: 0 0 10px;
  background: black;
  align-items: center;
  justify-content: center;
}

.timezone-select-input select {
  height: 30px;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 5px;
}

body,
html {
  margin: 0;
  padding: 0;
  background: #131521;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.6;
}

.good {
  color: green;
}

.bad {
  color: red;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  outline: none;
}

button {
  border-radius: 4px;
  background-color: #f9305b;
  /* -webkit-box-shadow: 0 5px 10px 0 rgba(249, 48, 91, 0.43); */
  /* box-shadow: 0 5px 10px 0 rgba(249, 48, 91, 0.43); */
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #ffffff;
  padding: 15px 25px;
  border: none;
}
button.small {
  font-size: 12px;
  padding: 7px 10px;
}

button.outlined {
  background-color: transparent;
  border: 1px solid #f9305b;
}

button:hover {
  box-shadow: 0 5px 17px 0 rgba(249, 48, 91, 0.5);
  cursor: pointer;
}

button:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

button:disabled {
  opacity: 0.5;
}

button:active {
  outline: none;
}

table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}

table tr {
  border-bottom: 2px solid #2c2c2d;
}

table th {
  font-size: 14px;
  padding: 10px 15px;
}

table td,
.ReactTable .rt-td {
  padding: 10px 15px;
  color: #7b8ca9;
  white-space: nowrap;
}

table td.user img {
  vertical-align: middle;
  border-radius: 50%;
  width: 20px;
  margin-right: 10px;
}

input,
textarea {
  background: #3b3f50;
  color: #ffffff;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
}

a {
  color: #97a8ff;
  cursor: pointer;
}

a:hover {
  text-decoration: underline;
}

hr {
  border-color: #2c2c2d;
  border-style: solid;
  margin: 25px 0;
}

.good {
  color: green;
}

.bad {
  color: red;
}

.content {
  margin: 0 auto;
  padding: 0 25px;
}

.page {
  background: #1d1e25;
  border-radius: 10px;
  padding: 25px;
  min-height: 50vh;
  overflow-x: auto;
  max-height: calc(100vh - 119px);
}
@media (max-width: 1024px) {
  .page {
    padding: 10px;
    max-height: 100vh;
    margin-bottom: 90px;
  }
}

.page h3 {
  margin-top: 0;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-group input:not([type="checkbox"]) {
  width: 100%;
}

input:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.input-group input {
  margin-bottom: 25px;
}

.input-group .input-row input {
  margin: 0;
  margin-left: 20px;
}
.input-group .input-row {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

#intercom-container {
  right: 50px;
}

.input-group > * {
  margin-bottom: 25px;
}

.input-group > * {
  width: 100%;
}

.esports-bets .esports-bet-slip {
  margin-bottom: 100px;
  text-align: left;
  border-radius: 4px;
  padding: 25px;
}

#user .esports-bets .esports-bet-slip h4 {
  text-transform: uppercase;
  margin: 0;
}

.esports-bets .esports-bet-slip table {
  table-layout: fixed;
  width: 100%;
  margin: 0;
}

.esports-bets .esports-bet-slip .top {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.esports-bets .esports-bet-slip .top .right {
  background: #2b2c37;
  text-transform: capitalize;
  padding: 10px 15px;
  font-size: 12px;
  border-radius: 4px;
  margin-left: 25px;
}

.esports-bets .esports-bet-slip table th {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.esports-bets .esports-bet-slip table td {
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b8ca9;
  border-bottom: none;
}

.esports-bets .esports-bet-slip table tr:not(:last-child) td {
  border-bottom: solid 2px #272b41;
}

.esports-bets .esports-bet-slip .top .right.status.win {
  background: #1bb67f;
}

.esports-bets .esports-bet-slip .top .right.status.loss {
  background: #5a3b3b;
}

.esports-bets .esports-bet-slip .top .right.status.refund {
  background: #b68d1b;
}

.Select input {
  color: #333;
}

.draggable-tags {
  display: flex;
  align-items: flex-start;
  min-height: 110px;
}

.draggable-tags .tag {
  padding: 15px;
  border-radius: 4px;
  background: #3b3f50;
}

.draggable-tags .tag i {
  margin-left: 15px;
  cursor: pointer;
}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.7) !important;
}

.ReactModal__Content {
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-th,
.ReactTable .rt-td {
  overflow: visible !important;
  text-align: left;
}

.ReactTable .-pagination .-btn {
  background-color: #f9305b !important;
  color: #ffffff !important;
}

.mobileVersion {
  height: 100vh;
  display: flex;
  flex-wrap: initial;
  text-align: center;
  padding: 0 10px;
}

.mobileVersion:first-child {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sub-nav a.active {
  border-bottom: none;
}
.loading-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(29, 30, 37, 0.9);
  z-index: 2;
  -webkit-animation: fade-in 800ms ease;
          animation: fade-in 800ms ease;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 100px;
  flex-direction: column;
}

.navigation-show {
  display: flex;
  justify-content: space-between;
}

.button-logout {
  margin-top: 20px;
  height: 45px;
  width: 100px;
  border-radius: 5px;
  background-color: #131521;
}

.button-logout:hover {
  box-shadow: none;
}
.navigation-rightside {
  display: flex;
}
.user-navigation {
  margin-top: 30px;
}

.responsiveTable{width:100%;background-color:#131521}.responsiveTable td .tdBefore{display:none}@media screen and (max-width: 40em){.responsiveTable table,.responsiveTable thead,.responsiveTable tbody,.responsiveTable th,.responsiveTable td,.responsiveTable tr{display:block}.responsiveTable thead tr{position:absolute;top:-9999px;left:-9999px;border-bottom:2px solid #333}.responsiveTable tbody tr{border:1px solid #000;margin-bottom:10px;background-color:#1d1e25}.responsiveTable td.pivoted{border:none !important;position:relative;padding-left:calc(50% + 10px) !important;white-space:pre-wrap;overflow-wrap:break-word;min-height:38px}.responsiveTable td .tdBefore{position:absolute;display:block;left:1rem;width:calc(50% - 20px);white-space:pre-wrap;overflow-wrap:break-word;font-weight:600;font-size:12px;text-align:right}}
